import styled from 'styled-components';
import { flexbox } from 'styles/utils';

export default function OpensourceBox({ os }: any) {
  const { title, period, repoLink, projects } = os;

  return (
    <Wrapper className={title === 'ExporterHub' ? 'bn' : undefined}>
      <LeftSide>
        <h3>
          <a href={repoLink} target="_blank" rel="noreferrer">
            {title}
          </a>
        </h3>
        <p className="small">{period}</p>
      </LeftSide>
      <RightSide>
        {projects?.map((project: any) => {
          return (
            <div>
              <h4>{project.title}</h4>
              <p className="desc">{project.description}</p>

              <h5>My Contributions</h5>
              <ul>
                {project.contributions.map((feature: any) => {
                  return <li>{feature}</li>;
                })}
              </ul>
              <h5>Tech Stacks</h5>
              <Tags>
                {project.techStacks?.map((tech: any) => (
                  <Tag>{tech}</Tag>
                ))}
              </Tags>
            </div>
          );
        })}
      </RightSide>
    </Wrapper>
  );
}

const Tags = styled.div`
  ${flexbox('flex-start', 'flex-start')};
  flex-wrap: wrap;
  gap: 8px;
`;

const Tag = styled.div`
  background-color: #ececec;
  font-size: 14px;
  padding: 2px 4px;
  border-radius: 3px;

  @media (max-width: 688px) {
    font-size: 13px;
  }
`;

const Wrapper = styled.div`
  margin-top: 50px;
  ${flexbox('flex-start', 'flex-start')};

  padding-bottom: 40px;
  border-bottom: 1px solid rgb(224, 226, 231);

  &.bn {
    border: none;
  }

  @media (max-width: 688px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.section`
  width: 32%;

  h3 {
    font-size: 25px;
    margin-bottom: 10px;

    &::after {
      margin-left: 0.15em;
      font-weight: 500;
      content: '↗️';
    }

    &:hover {
      text-decoration: underline;
    }
  }

  p.small {
    font-size: 18px;
    color: #525252;
  }

  @media (max-width: 688px) {
    width: 100%;
    margin-bottom: 20px;

    h3 {
      font-size: 20px;
    }

    p.small {
      font-size: 15px;
    }
  }
`;

const RightSide = styled.section`
  width: 68%;
  ${flexbox()};
  flex-direction: column;
  gap: 40px;

  h4 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  p.desc {
    font-size: 18px;
  }

  h5 {
    font-size: 18px;
    margin: 25px 0 10px 0px;
  }

  ul li {
    line-height: 1.5;
    font-size: 18px;
    padding-left: 10px;
    position: relative;

    &::before {
      position: absolute;
      top: 0.75rem;
      left: 0px;
      width: 4px;
      height: 4px;
      background-color: black;
      border-radius: 50%;
      content: '';
    }
  }

  @media (max-width: 688px) {
    width: 100%;

    h4 {
      font-size: 18px;
    }

    p.desc {
      font-size: 15px;
    }

    h5 {
      font-size: 15px;
    }

    ul li {
      font-size: 15px;

      &::before {
        top: 0.61rem;
      }
    }
  }
`;
