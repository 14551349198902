import styled from 'styled-components';
import edit from 'assets/demo/blog/edit.gif';
import { useState } from 'react';
import { Loading } from './DemoTodo';
import { Labels } from 'pages/Home/components/ProjectSection';

export default function DemoBlog() {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <DemoWrapper>
        {!imageLoaded && <Loading />}
        <img
          alt="Post editing"
          src={edit}
          onLoad={handleImageLoad}
          style={{
            display: imageLoaded ? 'block' : 'none',
            border: imageLoaded ? '2px solid black' : 'none',
          }}
        />
      </DemoWrapper>
    </>
  );
}

const DemoWrapper = styled.div`
  width: 100%;

  & > img {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
  }
`;

export const ReadmeBlog = () => {
  return (
    <>
      <div>
        <Feature>Sign-up, Login, Logout (Firebase authentication)</Feature>

        <Feature>
          Firebase (Email login, Firestore database) Integration
        </Feature>
        <SubFeature>
          Integrate Firebase for email-based user login and use Firestore
          database for storing and retrieving data
        </SubFeature>

        <Feature>Post Management</Feature>
        <SubFeature>Create, Edit, Delete Posts</SubFeature>
        <SubFeature>
          Edit and delete buttons appear only when the logged-in user matches
          the post author
        </SubFeature>

        <Feature>Firebase CRUD Operations</Feature>
        <SubFeature>
          Firebase's CREATE (add), READ (list, details), UPDATE (modify),
          DELETE(remove) APIs (using useQuery)
        </SubFeature>

        <Feature>Viewing posts</Feature>
        <SubFeature>View my posts in the post list and profile</SubFeature>
        <SubFeature>Filtering posts by category</SubFeature>

        <Feature>Global State Management with Context API</Feature>
        <SubFeature>Dark Mode</SubFeature>
        <SubFeature>User Authentication</SubFeature>

        <Feature>Responsive UI</Feature>
        <SubFeature>Desktop, Tablet, Mobile Support</SubFeature>
      </div>
      <SubTitle>Tech Stacks</SubTitle>
      <div>
        <Labels
          labels={[
            'React',
            'TypeScript',
            'PostCSS',
            'React-query',
            'Firebase',
            'Firestore',
            'ContextAPI',
            'React-hook-form',
            'yup',
            'Github',
            'Netlify',
          ]}
        />
      </div>
    </>
  );
};

const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 50px 0px 20px 0px;

  @media (max-width: 1000px) {
    font-size: 15px;
    margin: 40px 0px 20px 0px;
  }
`;

const Feature = styled.p`
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
  padding-left: 10px;
  line-height: 1.5;

  &::after {
    content: '';
    width: 3px;
    height: 3px;
    background-color: black;
    position: absolute;
    top: 50%; /* 점을 수직 중앙에 정렬합니다. */
    left: 0px; /* 점과 텍스트 사이의 간격을 조절합니다. */
    transform: translateY(-50%); /* 수직 중앙 정렬을 위한 변환 */
  }
`;

const SubFeature = styled.p`
  font-size: 15px;
  padding-left: 30px;
  line-height: 1.5;
`;
