export const works = [
  {
    company: 'Claion',
    period: '2023.1-2023.8',
    companyLink: 'https://www.claion.co.kr/en',
    projects: [
      {
        title: 'ClaiOps : Cloud Management Platform',
        description:
          'ClaiOps is a Cloud Management Platform (CMP) that provides an integrated dashboard, offering information on cloud costs and resource allocation compared to usage, to support effective cloud management internally. I developed user portals and an administrator portal, creating the user interface based on designs received from the designer. Additionally, I led the front-end team, managed the entire client codebase, developed core components, and documented them for sharing with fellow developers.',
        details: [
          'Set up a React-based client development environment',
          'Implemented and maintained the front-end interface',
          'Implemented a login system',
          'Created and maintained a design system',
          'Developed UI components',
          'Implemented dashboard screens using various type of charts (using e-chart)',
          'Developed core source code for globally managed Drawer and Modal components',
        ],
        techStacks: [
          'React 18',
          'TypeScript',
          'React-Query',
          'Styled-component',
          'e-chart',
        ],
        devEnvironment: ['Jenkins CI/CD', 'Postman'],
      },
      {
        title: 'ClaiObserver : cloud-native observability solution',
        link: 'https://claiobserver.claion.io/product/',
        description:
          'ClaiObserver is a platform for monitoring resources in a cloud-native environment, offering Guided dashboards and effective incident management features as a SaaS web application. I was responsible for maintaining and adding new features to this product. To enhance the product, I improved its entire functionality and actively participated in discussions regarding additional features.',
        details: [
          'Maintained and developed new features based on the existing codebase',
          'Problem-solving and troubleshooting',
          'Feature enhancements',
          'Implemented a cluster installation screen based on Helm',
          'Created an admin interface with branch logic using auth checks',
          'Implemented individual JWT token retrieval from the admin interface for entry into the tenant portal',
          'Developed a drill-down dashboard view',
        ],
        techStacks: [
          'React',
          'TypeScript',
          'React-Query',
          'Styled-component',
          'PostCSS',
          'Jotai',
          'e-chart',
        ],
        devEnvironment: ['Jenkins CI/CD', 'Postman'],
      },
    ],
  },

  {
    company: 'NexCloud',
    period: '2021.4-2023.1',
    companyLink: 'https://www.kubeaiops.com/en/',
    projects: [
      {
        title: 'NexClipper : Observability for Cloud-Native Environments',
        description:
          'This project, which I participated in from MVP to the open beta testing phase, is a SaaS application that provides a monitoring solution with Prometheus. It involves provisioning agents in Kubernetes environments to collect metrics and monitor resources. It offers an MSP integrated dashboard, the ability to generate incident tickets when alerts occur, and enables access group users to manage their allocated resources. Additionally, it provides functionality to automatically resolve issues through anomaly detection. I was responsible for the overall UI development of all these features, and at times, on occasion, API development as well.',
        details: [
          'Implemented the front-end interface',
          'Managed login and global state using jotai',
          'Multilingual support 🇰🇷 🇺🇸',
          'Created and maintained email templates',
          'Managed local time-based data for global SaaS support',
          'Developed UI components',
          'Established and maintained a design system',
          'Implemented user input forms and screens for reviewing user input',
          'Implemented synchronous screens based on real-time server responses (cluster installation)',
          'Created scheduler and task management screens',
          'Developed a module for generating dynamic form elements based on templates',
        ],
        techStacks: [
          'React',
          'TypeScript',
          'React-Query',
          'React-Table',
          'Styled-component',
          'x-style',
          'React-Hook-Form',
          'day.js',
          'i18n',
          'jotai',
          'ejs',
          'e-chart',
        ],
        devEnvironment: [
          'Kubernetes',
          'Digital Ocean',
          'Harbor',
          'Vercel',
          'Swagger',
          'Postman',
        ],
      },
    ],
  },
];
