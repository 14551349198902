import styled from 'styled-components';
import todo from 'assets/demo/todo/todo.gif';
import { useState } from 'react';
import star from 'assets/star.png';
import { flexbox } from 'styles/utils';
import { Labels } from 'pages/Home/components/ProjectSection';

export default function DemoTodo() {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <DemoWrapper>
      {!imageLoaded && <Loading />}
      <img
        alt="TodoApp Demo"
        src={todo}
        onLoad={handleImageLoad}
        style={{
          display: imageLoaded ? 'block' : 'none',
          border: imageLoaded ? '2px solid black' : 'none',
        }}
      />
    </DemoWrapper>
  );
}

const DemoWrapper = styled.div`
  width: 100%;

  & > img {
    width: 100%;
    margin-bottom: 10px;
    border: 2px solid black;
    border-radius: 10px;
  }
`;

export const Loading = () => {
  return (
    <LoadingBox>
      <Star src={star} alt="star" />
    </LoadingBox>
  );
};

const LoadingBox = styled.div`
  width: 100%;
  height: 300px;
  ${flexbox()};

  @media (max-width: 1000px) {
    height: 150px;
  }
`;

const Star = styled.img`
  width: 90px;
  position: relative;
  top: -10px;
  animation: floating 2s ease infinite;

  @keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(15px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 800px) {
    width: 70px;
  }
`;

export const ReadmeTodo = () => {
  return (
    <>
      <div>
        <Feature>LocalStorage Data Storage</Feature>

        <SubFeature>
          Implement data storage using LocalStorage for a seamless user
          experience
        </SubFeature>

        <Feature>Dark Mode Support</Feature>

        <SubFeature>
          Add support for a dark mode theme to enhance user accessibility
        </SubFeature>

        <Feature>Design Integration</Feature>
        <SubFeature>
          Design the application screens using Figma and then proceed with
          front-end development based on the design.
        </SubFeature>
        <Feature> Task Filtering</Feature>
        <SubFeature>
          Three task filtering options: All, Active, and Completed.
        </SubFeature>
        <SubFeature>
          Allow users to switch between these filters to easily manage and view
          their tasks based on their status.
        </SubFeature>
      </div>
      <SubTitle>Tech Stacks</SubTitle>
      <div>
        <Labels
          labels={[
            'React',
            'PostCSS',
            'ContextAPI',
            'Github',
            'Netlify',
            'Figma',
          ]}
        />
      </div>
    </>
  );
};

const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 50px 0px 20px 0px;

  @media (max-width: 1000px) {
    font-size: 15px;
    margin: 40px 0px 20px 0px;
  }
`;

const Feature = styled.p`
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
  padding-left: 10px;
  line-height: 1.5;

  &::after {
    content: '';
    width: 3px;
    height: 3px;
    background-color: black;
    position: absolute;
    top: 50%; /* 점을 수직 중앙에 정렬합니다. */
    left: 0px; /* 점과 텍스트 사이의 간격을 조절합니다. */
    transform: translateY(-50%); /* 수직 중앙 정렬을 위한 변환 */
  }
`;

const SubFeature = styled.p`
  font-size: 15px;
  padding-left: 30px;
  line-height: 1.5;
`;
