import { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { flexbox } from 'styles/utils';
import DemoBlog, { ReadmeBlog } from './Demo/DemoBlog';
import DemoCanvas, { ReadmeCanvas } from './Demo/DemoCanvas';
import DemoShoppy, { ReadmeShoppy } from './Demo/DemoShoppy';

import DemoTodo, { ReadmeTodo } from './Demo/DemoTodo';

export default function Project({ project, setModal }: any) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <Modal>
        <Section>
          <header>
            <h1>{project.title}</h1>
            <div>
              {project?.link && (
                <LiveDemo href={project.link} target="_blank">
                  Live Demo
                </LiveDemo>
              )}
              {project?.github && (
                <LiveDemo href={project.github} target="_blank">
                  Github
                </LiveDemo>
              )}
            </div>
          </header>

          <SubTitle>Demo</SubTitle>
          {project.path === 'todo' && <DemoTodo />}
          {project.path === 'shoppy' && <DemoShoppy />}
          {project.path === 'blog' && <DemoBlog />}
          {project.path === 'canvas' && <DemoCanvas />}

          <SubTitle>Details</SubTitle>

          {project.path === 'todo' && <ReadmeTodo />}
          {project.path === 'shoppy' && <ReadmeShoppy />}
          {project.path === 'blog' && <ReadmeBlog />}
          {project.path === 'canvas' && <ReadmeCanvas />}
        </Section>
      </Modal>
    </>
  );
}

const LiveDemo = styled.a`
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;

  &::after {
    margin-left: 0.15em;
    font-weight: 400;
    content: '↗️';
  }

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 50px 0px 20px 0px;

  @media (max-width: 1000px) {
    font-size: 15px;
    margin: 40px 0px 10px 0px;
  }
`;

const Section = styled.section`
  width: 1000px;
  height: fit-content;
  padding: 80px;

  header {
    ${flexbox('space-between')}

    div {
      ${flexbox()};
      gap: 10px;
    }
  }

  h1 {
    font-size: 24px;
  }

  @media (max-width: 1800px) {
    width: 800px;
  }

  @media (max-width: 1000px) {
    h1 {
      font-size: 20px;
    }

    width: 100%;
    padding: 50px;
  }

  @media (max-width: 700px) {
    header {
      flex-direction: column;
      ${flexbox('flex-start', 'flex-start')};
      gap: 10px;
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999999;
  opacity: 0;
  animation: ${fadeIn} 0.3s; /* fadeIn 애니메이션을 1초 동안 적용합니다. */
  animation-fill-mode: forwards; /* 애니메이션 종료 시 마지막 스타일 유지 */
`;

const Modal = styled.div`
  width: 1000px;
  height: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  border-radius: 20px;
  background-color: #ffffff;
  z-index: 99999999;
  box-shadow: 10px 10px 80px 0px rgba(0, 0, 0, 0.3);
  overflow: auto;

  @media (max-width: 1800px) {
    width: 800px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    height: 80%;
    border-radius: 2rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    bottom: 0; /* 화면 하단에 붙도록 설정 */
    top: auto; /* top 속성 제거 */
    transform: translate(-50%, 0); /* 수직 중앙 정렬 제거 */
    box-shadow: none; /* 그림자 제거 */
  }
`;
