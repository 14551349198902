import styled from 'styled-components';
import shoppy from 'assets/demo/shoppy/shoppy1.gif';
import { useState } from 'react';
import { Loading } from './DemoTodo';
import { Labels } from 'pages/Home/components/ProjectSection';

export default function DemoShoppy() {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <DemoWrapper>
      {!imageLoaded && <Loading />}
      <img
        alt="Shoppy Demo"
        src={shoppy}
        onLoad={handleImageLoad}
        style={{
          display: imageLoaded ? 'block' : 'none',
          border: imageLoaded ? '2px solid black' : 'none',
        }}
      />
    </DemoWrapper>
  );
}

const DemoWrapper = styled.div`
  width: 100%;

  & > img {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
  }
`;

export const ReadmeShoppy = () => {
  return (
    <>
      <div>
        <Feature>Login (Google OAuth login using Firebase)</Feature>
        <Feature>User branching</Feature>
        <SubFeature>Authentication (checking login status)</SubFeature>
        <SubFeature>Authorization (regular user, admin user)</SubFeature>

        <Feature>
          Firebase (Google login, real-time database usage) Connecting
        </Feature>
        <Feature>
          Firebase's CREATE (add), READ (list, details), UPDATE (modify),
          DELETE(remove) APIs (using useQuery)
        </Feature>

        <Feature>Shopping cart feature </Feature>
        <SubFeature>
          Changing the shopping cart badge when adding or removing items
        </SubFeature>
        <SubFeature>
          Updating the total order price when changing the quantity of items
        </SubFeature>
        <SubFeature>Cart item deletion </SubFeature>

        <Feature>Admin features</Feature>
        <SubFeature>Product registration</SubFeature>
        <SubsubFeature>
          Image upload (using Cloudinary, adding new products to Firebase)
        </SubsubFeature>

        <Feature>Responsive UI</Feature>
        <SubFeature>Desktop, Tablet, Mobile Support</SubFeature>
      </div>
      <SubTitle>Tech Stacks</SubTitle>
      <div>
        <Labels
          labels={[
            'React',
            'React-query',
            'Tailwind CSS',
            'Firebase',
            'Cloudinary',
            'Github',
            'Netlify',
            'Figma',
          ]}
        />
      </div>
    </>
  );
};

const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 50px 0px 20px 0px;

  @media (max-width: 1000px) {
    font-size: 15px;
    margin: 40px 0px 20px 0px;
  }
`;

const Feature = styled.p`
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
  padding-left: 10px;
  line-height: 1.5;

  &::after {
    content: '';
    width: 3px;
    height: 3px;
    background-color: black;
    position: absolute;
    top: 50%; /* 점을 수직 중앙에 정렬합니다. */
    left: 0px; /* 점과 텍스트 사이의 간격을 조절합니다. */
    transform: translateY(-50%); /* 수직 중앙 정렬을 위한 변환 */
  }
`;

const SubFeature = styled.p`
  font-size: 15px;
  padding-left: 30px;
  line-height: 1.5;
`;
const SubsubFeature = styled.p`
  font-size: 15px;
  padding-left: 60px;
  line-height: 1.5;
`;
