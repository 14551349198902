import IndexSection from './components/IndexSection';
import ProjectSection from './components/ProjectSection';
import Navbar from './components/Navbar';
import TestimonialSection from './components/TestimonialSection';

export default function Home() {
  return (
    <div>
      <Navbar />
      <IndexSection />
      <ProjectSection />
      <TestimonialSection />
    </div>
  );
}
