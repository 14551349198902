import styled from 'styled-components';
import { flexbox } from 'styles/utils';
import star from 'assets/star.png';
import { useState } from 'react';
import Project from 'pages/Project/Project';

const PROJECTS = [
  {
    title: 'Post-It Hero: Command Your Tasks',
    preview:
      'https://res.cloudinary.com/djvbl7f0n/image/upload/v1696063474/todoapp_mqodl8.png',
    labels: ['React', 'PostCSS', 'ContextAPI', 'Darkmode', 'Figma'],
    desc: 'A simple Todo App. It supports dark mode, and the written to-dos are stored in local storage, so the data is retained even upon revisiting.',
    link: 'https://rainbow-madeleine-9029c6.netlify.app/',
    path: 'todo',
    github: 'https://github.com/yoosaemsol/todolist-2023',
  },
  {
    title: 'Blogloft: Your Stories, Your Box',
    preview:
      'https://res.cloudinary.com/djvbl7f0n/image/upload/v1696093822/%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA_2023-10-01_%E1%84%8B%E1%85%A9%E1%84%8C%E1%85%A5%E1%86%AB_2.10.04_mv0xrc.png',
    labels: [
      'React',
      'TypeScript',
      'PostCSS',
      'React-query',
      'Firebase',
      'Firestore',
      'Responsive',
      'Darkmode',
    ],
    desc: 'Blog App project for practicing Firebase and Firestore. After signing up with an email, users can write posts by category, and only the author can edit or delete them.',
    link: 'https://dreamy-blini-b9070e.netlify.app/',
    path: 'blog',
    github: 'https://github.com/yoosaemsol/react-blog-app',
  },
  {
    title: 'Shoppy: Taste Your Tastes!',
    preview:
      'https://res.cloudinary.com/djvbl7f0n/image/upload/v1696063473/shoppingapp_tyzppl.png',
    labels: [
      'React',
      'React-query',
      'Tailwind CSS',
      'Firebase',
      'Cloudinary',
      'Responsive',
      'UI/UX',
      'Figma',
    ],
    desc: 'Shopping Mall App built with React and Firebase. Users can sign up using Google account. Admins can update shopping items, regular users can add items to their carts.',
    link: 'https://superlative-nasturtium-4b8d8b.netlify.app/',
    path: 'shoppy',
    github: 'https://github.com/yoosaemsol/react-shoppy-2023',
  },
  {
    title: "Scratch: What's on Next",
    preview:
      'https://res.cloudinary.com/djvbl7f0n/image/upload/v1696164021/%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA_2023-10-01_%E1%84%8B%E1%85%A9%E1%84%92%E1%85%AE_9.38.21_sf4bn9.png',
    labels: [
      'React',
      '2D Canvas',
      'JavaScript',
      'Interactive Web',
      'lodash/throttle',
      'gsap',
    ],
    desc: 'Interactive web development practice project with 2D Canvas.',
    path: 'canvas',
  },
];

export const Labels = ({ labels }: any) => {
  return (
    <LabelWapper>
      {labels?.map((label: string, index: number) => (
        <span key={index}>{label}</span>
      ))}
    </LabelWapper>
  );
};

const LabelWapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  width: 100%;

  span {
    background-color: #f5f4f4;
    padding: 5px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
  }

  @media (max-width: 500px) {
    margin-bottom: 20px;

    span {
      font-size: 10px;
    }
  }
`;

export default function ProjectSection() {
  const [modal, setModal] = useState(null);

  return (
    <>
      <Wapper id="projects">
        <h1>Projects</h1>
        <div>
          {PROJECTS?.map((pj: any, index: number) => {
            return (
              <Card $preview={pj.preview} key={index}>
                <div className="detail">
                  <div>
                    <h3 onClick={() => setModal(pj)}>{pj.title}</h3>
                    <Labels labels={pj.labels} />
                    <p>{pj.desc}</p>
                  </div>
                  <div className="utils">
                    <button onClick={() => setModal(pj)}>View Detail</button>
                    {pj?.link && (
                      <button>
                        <a href={pj.link} target="_blank" rel="noreferrer">
                          Live Demo ↗️
                        </a>
                      </button>
                    )}
                    {pj?.github && (
                      <button>
                        <a href={pj.github} target="_blank" rel="noreferrer">
                          Github Repo ↗️
                        </a>
                      </button>
                    )}
                  </div>
                </div>
                <div className="preview" />
              </Card>
            );
          })}
        </div>

        <Star src={star} alt="star" className="star1" />
        <Star src={star} alt="star" className="star2" />
      </Wapper>
      {modal && <Project project={modal} setModal={setModal} />}
    </>
  );
}

const Star = styled.img`
  position: absolute;

  &.star1 {
    width: 90px;

    top: 100px;
    right: 100px;
  }

  &.star2 {
    width: 40px;
    bottom: 80px;
    left: 80px;
  }

  animation: floating 2s ease infinite;

  @keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(15px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 800px) {
    &.star1 {
      width: 70px;
      top: 20px;
      right: 30px;
    }

    &.star2 {
      bottom: 20px;
      left: 25px;
    }
  }
`;

const Wapper = styled.section`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  padding: 100px;
  padding-top: 300px;
  position: relative;

  background-color: #f5f4f4;

  h1 {
    font-size: 30px;
    position: absolute;
    top: 130px;
    left: 130px;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;

    @media (max-width: 1400px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @media (max-width: 800px) {
    padding: 40px;
    padding-top: 150px;

    h1 {
      font-size: 20px;
      top: 80px;
      left: 40px;
    }
  }
`;

const Card = styled.div<{ $preview: string }>`
  ${flexbox('space-between', 'flex-start')};
  width: 100%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 20px;
  gap: 15px;

  .detail {
    width: 70%;
    height: 300px;
    ${flexbox('space-between', 'flex-start')};
    flex-direction: column;

    & > div {
      width: 100%;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      font-size: 18px;
      font-weight: 500;
      word-wrap: break-word;
      line-height: 1.5;
      margin-bottom: 10px;
    }

    .utils {
      ${flexbox('flex-start')};
      gap: 10px;

      button {
        padding: 8px 15px;
        font-size: 15px;
        font-weight: 600;
        border-radius: 20px;
        border: 1px solid black;
        background-color: inherit;
        transition: all ease-in 0.1s;

        &:hover {
          background-color: black;
          color: white;
        }
      }
    }
  }

  .preview {
    width: 180px;
    height: 110px;
    border-radius: 5px;
    background-image: ${(props) => `url('${props.$preview}')`};
    background-size: cover; /* 배경 이미지 크기 조정 (가득 채우기) */
    background-position: center center; /* 배경 이미지 위치 (가운데 정렬) */
    background-repeat: no-repeat; /* 배경 이미지 반복 없음 */
  }

  @media (max-width: 1400px) {
    .detail {
      height: fit-content;

      h3 {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;

    .preview {
      width: 100%;
      height: 130px;
      margin-bottom: 20px;
    }

    .detail {
      width: 100%;
      height: fit-content;

      h3 {
        margin-bottom: 15px;
        font-size: 15px;
      }

      p {
        font-size: 15px;
        height: fit-content;
        margin-bottom: 20px;
      }

      .utils {
        ${flexbox('center')};

        button {
          font-size: 12px;
          border-radius: 30px;
          width: 50%;
          padding: 8px 5px;
        }
      }
    }
  }
`;
