// @ts-nocheck

import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import gsap from 'gsap';

import star from 'assets/star.png';
import { flexbox } from 'styles/utils';
import { Labels } from 'pages/Home/components/ProjectSection';

import image1 from 'assets/demo/canvas/nudake-1.jpg';
import image2 from 'assets/demo/canvas/nudake-2.jpg';
import image3 from 'assets/demo/canvas/nudake-3.jpg';
import image4 from 'assets/demo/canvas/nudake-4.jpg';
import image5 from 'assets/demo/canvas/nudake-5.jpg';
import image6 from 'assets/demo/canvas/nudake-6.jpg';
import image7 from 'assets/demo/canvas/nudake-7.jpg';

export default function DemoCanvas() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const canvasParent = canvas.parentNode;
    const ctx = canvas.getContext('2d');

    const imageSrcs = [image1, image2, image3, image4, image5, image6, image7];
    const loadedImages = [];
    let currentIndex = 0;
    let prevPos = { x: 0, y: 0 };
    let isChanging = false;

    let canvasWidth, canvasHeight;

    function resize() {
      canvasWidth = canvasParent.clientWidth;
      canvasHeight = canvasParent.clientHeight;
      canvas.style.width = canvasWidth + 'px';
      canvas.style.height = canvasHeight + 'px';
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      preloadImages().then(() => {
        drawImage();
      });
    }

    function preloadImages() {
      return new Promise((resolve, reject) => {
        let loaded = 0;
        imageSrcs.forEach((src) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            loaded += 1;
            loadedImages.push(img);
            if (loaded === imageSrcs.length) return resolve();
          };
        });
      });
    }

    function drawImage() {
      isChanging = true;

      const image = loadedImages[currentIndex];
      const firstDrawing = ctx.globalCompositeOperation === 'source-over';

      gsap.to(canvas, {
        opacity: 0,
        duration: firstDrawing ? 0 : 1,
        onComplete: () => {
          canvas.style.opacity = 1;
          ctx.globalCompositeOperation = `source-over`;

          drawImageCenter(canvas, ctx, image);

          const nextImage = imageSrcs[(currentIndex + 1) % imageSrcs.length];
          canvasParent.style.backgroundImage = `url(${nextImage})`;
          prevPos = null;

          isChanging = false;
        },
      });
    }

    function onMouseDown(e) {
      if (isChanging) return;

      canvas.addEventListener('mouseup', onMouseUp);
      canvas.addEventListener('mouseleave', onMouseUp);
      canvas.addEventListener('mousemove', onMouseMove);
      prevPos = { x: e.offsetX, y: e.offsetY };
    }

    function onMouseUp() {
      canvas.removeEventListener('mouseup', onMouseUp);
      canvas.removeEventListener('mouseleave', onMouseUp);
      canvas.removeEventListener('mousemove', onMouseMove);
    }

    function onMouseMove(e) {
      if (isChanging) return;

      drawCircles(e);
      checkPercent();
    }

    function drawCircles(e) {
      const nextPos = { x: e.offsetX, y: e.offsetY };
      if (!prevPos) prevPos = nextPos;

      const dist = getDistance(prevPos, nextPos);
      const angle = getAngle(prevPos, nextPos);

      for (let i = 0; i < dist; i++) {
        const x = prevPos.x + Math.cos(angle) * i;
        const y = prevPos.y + Math.sin(angle) * i;

        ctx.globalCompositeOperation = `destination-out`;
        ctx.beginPath();
        ctx.arc(x, y, canvasWidth / 15, 0, Math.PI * 2);
        ctx.fill();
        ctx.closePath();
      }

      prevPos = nextPos;
    }

    const checkPercent = throttle(() => {
      const percent = getScrupedPercent(ctx, canvasWidth, canvasHeight);

      if (percent > 50) {
        currentIndex = (currentIndex + 1) % imageSrcs.length;
        drawImage();
      }
    }, 500);

    canvas.addEventListener('mousedown', onMouseDown);
    window.addEventListener('resize', resize);
    resize();

    return () => {
      canvas.removeEventListener('mousedown', onMouseDown);

      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <>
      <p
        style={{
          fontSize: '15px',
          fontWeight: 600,
          textDecoration: 'underline',
          marginBottom: '20px',
        }}
      >
        Try scratching the picture!
      </p>
      <DemoWrapper>
        <div className="loading">
          <Loading />
        </div>
        <div className="nudake">
          <canvas ref={canvasRef} />
        </div>
      </DemoWrapper>
      <p style={{ marginTop: '10px', lineHeight: 1.5 }}>
        (Only accessible on a computer, not compatible with mobile or tablet
        devices.)
      </p>
    </>
  );
}

const DemoWrapper = styled.div`
  width: 100%;
  height: 600px;
  border: 2px solid black;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .nudake {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .nudake > canvas {
    position: absolute;
  }

  @media (max-width: 500px) {
    height: 300px;
  }
`;

export const Loading = () => {
  return (
    <LoadingBox>
      <Star src={star} alt="star" />
    </LoadingBox>
  );
};

const LoadingBox = styled.div`
  width: 100%;
  height: 300px;
  ${flexbox()};

  @media (max-width: 1000px) {
    height: 150px;
  }
`;

const Star = styled.img`
  width: 90px;
  position: relative;
  top: -10px;
  animation: floating 2s ease infinite;

  @keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(15px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 800px) {
    width: 70px;
  }
`;

export const ReadmeCanvas = () => {
  return (
    <>
      <div>
        <Feature>
          This project involves working with a 2D canvas in React
        </Feature>

        <Feature>Throttle for Event Optimization</Feature>

        <SubFeature>
          Throttle is implemented to optimize event calls, ensuring smoother
          performance.
        </SubFeature>

        <Feature>Smooth Image Transitions with GSAP</Feature>
        <SubFeature>
          The GSAP library is utilized to achieve smooth and natural transitions
          between images, enhancing the overall user experience.
        </SubFeature>

        <Feature>Responsive Brush Size</Feature>
        <SubFeature>
          The brush size is dynamically adjusted based on the screen width,
          ensuring responsiveness and compatibility across various screen sizes.
        </SubFeature>
      </div>
      <SubTitle>Tech Stacks</SubTitle>
      <div>
        <Labels
          labels={[
            'React',
            '2D Canvas',
            'JavaScript',
            'lodash/throttle',
            'gsap',
          ]}
        />
      </div>
    </>
  );
};

const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 50px 0px 20px 0px;

  @media (max-width: 1000px) {
    font-size: 15px;
    margin: 40px 0px 20px 0px;
  }
`;

const Feature = styled.p`
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
  padding-left: 10px;
  line-height: 1.5;

  &::after {
    content: '';
    width: 3px;
    height: 3px;
    background-color: black;
    position: absolute;
    top: 50%; /* 점을 수직 중앙에 정렬합니다. */
    left: 0px; /* 점과 텍스트 사이의 간격을 조절합니다. */
    transform: translateY(-50%); /* 수직 중앙 정렬을 위한 변환 */
  }
`;

const SubFeature = styled.p`
  font-size: 15px;
  padding-left: 30px;
  line-height: 1.5;
`;

function getDistance(p1, p2) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;

  return Math.sqrt(dx * dx + dy * dy); // 피타고라스
}

function getAngle(p1, p2) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;

  return Math.atan2(dy, dx); // 두 점 사이의 절대적인 각도
}

function getScrupedPercent(ctx, width, height) {
  const pixels = ctx.getImageData(0, 0, width, height);
  const gap = 32; // 4의 배수 (퍼포먼스 성능을 위해 크게 잡음)
  const total = pixels.data.length / gap;
  let count = 0;

  for (let i = 0; i < pixels.data.length - 3; i += gap) {
    // 3으로 하는 이유는 r,g,b,a 가 4개인덱스를 잡기 때문
    if (pixels.data[i + 3] === 0) count++;
  }

  return Math.round((count / total) * 100);
}

function drawImageCenter(canvas, ctx, image) {
  const cw = canvas.width;
  const ch = canvas.height;

  const iw = image.width;
  const ih = image.height;

  const ir = ih / iw; // image ratio
  const cr = ch / cw; // canvas ratio

  let sx, sy, sw, sh;

  if (ir >= cr) {
    sw = iw;
    sh = sw * (ch / cw);
  } else {
    sh = ih;
    sw = sh * (cw / ch);
  }
  sx = iw / 2 - sw / 2;
  sy = ih / 2 - sh / 2;

  ctx.drawImage(image, sx, sy, sw, sh, 0, 0, cw, ch);
}
