export const opensources = [
  {
    title: 'ExporterHub',
    period: '2021.3-2021.4',
    repoLink: 'https://github.com/NexClipper/exporterhub.io',
    projects: [
      {
        title: 'A Curated List of Prometheus Exporters',
        description:
          'ExporterHub.io is an application for the Prometheus Exporters community.',
        contributions: [
          'Added GitHub OAuth login functionality.',
          'Implemented exporter registration and content modification features.',
          'Implemented My Bucket functionality with star and bookmark support.',
          'Added admin permissions.',
          'Implemented a markdown editor.',
        ],
        techStacks: [
          'React',
          'Redux',
          'JavaScript',
          'Styled-component',
          'Ant Design',
          'GitHub OAuth',
          'GitHub REST APIs',
        ],
      },
    ],
  },
  {
    title: 'Klevr',
    period: '2021.7-2021.9',
    repoLink: 'https://github.com/Klevry/klevr',
    projects: [
      {
        title: 'Hyper-connected Cloud-native delivery solution for SaaS',
        description:
          'Asynchronous distributed infrastructure management console and agent for separated networks.',
        contributions: [
          'Implemented the Console Activator screen.',
          "Implemented entering the console screen by selecting a 'zone' (all data separated by zone).",
          'Added functionality for adding/deleting zones. (*Zone is where agents are installed)',
          'Implemented a page for managing tasks and reviewing logs generated during task execution.',
          "Created a modal for generating tasks of types 'Order,' 'Scheduler,' and 'Provisioning' (form changes based on selected options).",
          'Managed credential information (addition, modification, removal).',
          'Added functionality for agent management (addition, removal).',
          'Implemented a Klevr Manager settings management page.',
        ],
        techStacks: ['React', 'Redux', 'JavaScript', 'material-ui'],
      },
    ],
  },
];
