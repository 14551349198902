import { useState } from 'react';
import styled from 'styled-components';
import { flexbox } from 'styles/utils';

const Card = ({ info }: any) => {
  return (
    <CardWrapper>
      <p>"{info.content}"</p>
      <div>
        <img src={info.avatar} alt={info.name} />
        <div>
          <p>{info.name}</p>
          <p>{info.info}</p>
          <p>{info.location}</p>
        </div>
      </div>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 30px;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.13);

  &:last-child {
    margin-bottom: 0px;
  }

  & > div {
    ${flexbox('flex-start', 'flex-start')};
    gap: 10px;
  }

  p {
    font-size: 16px;
    white-space: pre-line;
    margin-bottom: 20px;
    line-height: 1.3;
    font-weight: 500;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
  }

  div div {
    p:first-child {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    p:nth-child(2) {
      font-size: 12px;
      margin-bottom: 3px;
    }

    p:last-child {
      font-size: 10px;
      color: gray;
      font-weight: 500;
    }
  }
`;

const Avatar = ({ info, currentActive, setActive, order }: any) => {
  return (
    <AvatarWrapper
      $active={currentActive === order}
      onClick={() => setActive(order)}
    >
      <img src={info.avatar} alt={info.name} />
      <div>
        <p>{info.name}</p>
        <p>{info.info}</p>
        <p>{info.location}</p>
      </div>
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled.div<{ $active: boolean }>`
  width: 500px;
  padding: 20px;
  ${flexbox('flex-start', 'center')}
  gap: 15px;
  border-radius: 30px;
  box-shadow: ${(props) =>
    props?.$active ? '20px 20px 80px 0px rgba(0, 0, 0, 0.15)' : undefined};

  cursor: pointer;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  div {
    p:first-child {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    p:nth-child(2) {
      font-size: 15px;
      margin-bottom: 5px;
    }

    p:last-child {
      font-size: 15px;
      color: gray;
      font-weight: 500;
    }
  }

  @media (max-width: 1200px) {
    width: 400px;

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    div {
      p:first-child {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      p:nth-child(2) {
        font-size: 12px;
        margin-bottom: 3px;
      }

      p:last-child {
        font-size: 12px;
        color: gray;
        font-weight: 500;
      }
    }
  }
`;

export default function TestimonialSection() {
  const [active, setActive] = useState(0);

  return (
    <div id="testimonial">
      <Wapper>
        <h1>Testimonials</h1>
        <List>
          {testimonials?.map((item: any, index: number) => {
            return (
              <Avatar
                key={index}
                info={item}
                setActive={setActive}
                currentActive={active}
                order={index}
              >
                {item.name}
              </Avatar>
            );
          })}
        </List>
        <Content>
          <div>{testimonials[active]?.content}</div>
          <span>
            <a
              href={testimonials[active]?.link}
              target="_blank"
              rel="noreferrer"
            >
              {testimonials[active]?.name}
            </a>
            {`@ ${testimonials[active]?.where}`}
          </span>
        </Content>
        <Orbit>
          <div className="track"></div>
          <div className="ball"></div>
        </Orbit>
      </Wapper>
      <MobileWrapper>
        <h1>Testimonials</h1>
        {testimonials?.map((item: any, index: number) => {
          return (
            <Card
              key={index}
              info={item}
              setActive={setActive}
              currentActive={active}
              order={index}
            >
              {item.name}
            </Card>
          );
        })}
      </MobileWrapper>
    </div>
  );
}

const Orbit = styled.div`
  z-index: -999;
  position: absolute;
  top: 50px;
  right: -100px;
  width: 600px;
  height: 600px;
  animation: rotate 8s linear infinite;

  .track {
    width: 600px;
    height: 600px;
    border: 1px solid hsla(0, 0%, 50%, 0.227);
    border-radius: 50%;
  }

  .ball {
    width: 20px;
    height: 20px;
    background-color: #ffe894;
    position: absolute;
    left: 300px;
    top: -10px;
    border-radius: 50%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg); /* 0도 회전 시작 */
    }
    100% {
      transform: rotate(360deg); /* 360도 회전 종료 */
    }
  }

  @media (max-width: 1200px) {
    width: 500px;
    height: 500px;

    .track {
      width: 500px;
      height: 500px;
    }

    .ball {
      width: 16px;
      height: 16px;
      left: 250px;
      top: -8px;
    }
  }
`;

const MobileWrapper = styled.section`
  width: 100%;
  height: fit-content;
  padding: 130px;

  h1 {
    font-size: 20px;
    margin-bottom: 40px;
  }

  @media (max-width: 800px) {
    padding: 40px;
    padding-top: 100px;
  }

  @media (min-width: 1001px) {
    display: none;
  }
`;

const Wapper = styled.section`
  width: 100%;
  height: 100vh;
  padding: 100px;
  ${flexbox()}
  gap: 100px;
  position: relative;
  overflow: hidden;
  h1 {
    font-size: 30px;
    position: absolute;
    top: 130px;
    left: 130px;
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 30px;
      position: absolute;
      top: 100px;
      left: 40px;
    }
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const List = styled.div`
  ${flexbox()}
  flex-direction: column;
  gap: 50px;
`;

const Content = styled.div`
  width: 600px;
  font-size: 20px;
  line-height: 1.5;
  white-space: pre-line;

  span {
    display: inline-block;
    margin-top: 30px;
    a {
      margin-right: 10px;
      font-weight: 500;

      &::after {
        margin-left: 0.15em;
        font-weight: 400;
        content: '↗️';
      }
    }
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

const testimonials = [
  {
    name: 'Sabine Schatzer',
    info: 'Marketing Manager, Germany, Austria & CEE at EDB',
    location: 'Austria',
    content: `I had the pleasure of working with Saemsol for almost 2 years, specifically collaborating on cross-departmental projects involving the UI of our SaaS product as well as working with her on field marketing activities where deep technical product expertise was required. She has repeatedly impressed me deeply in all those projects. 

    Saemsol possesses an incredibly positive attitude and can-do spirit that she brings to any task, making her a joy to have on any team. Her proactive and diligent workstyle as well as creative thinking abilities were of immense value to the projects she was given. Over the course of my time with her, Saemsol has taken on responsibilities beyond her original role and proven to be a fast learner, even when it comes to complex technical topics. In addition to her work ethic and abilities, she also has proven to have great people skills and has been a wonderful representative for our company at trade shows etc. 
   
    I have no doubt that Saemsol will go far in her career and that she will be a fantastic addition to any team.`,
    avatar:
      'https://res.cloudinary.com/djvbl7f0n/image/upload/v1709063530/sabine_r3ux95.jpg',
    link: 'https://www.linkedin.com/in/sabine-schatzer/',
    where: 'NexCloud',
  },
  {
    name: 'Ryan Lee',
    info: 'Micro SaaS Founder | Full-stack Product Designer',
    location: 'Vancouver, BC',
    content: `I've worked as a full-stack staff developer with Selena for 2 years on an observability application monitoring the abnormality of OpenStack and Kubernetes clusters. Every front-end sprint was very challenging and complex in order to create an intuitive dashboard. She was someone you can count on in every sprint because she delivers both in quantity and quality. 

    To better understand the domain, she even created a small study group with a few other developers to study Cloud Native stacks such as Kubernetes and Prometheus. Besides her proficiency in modern web developer stacks, what I admire most is how dedicated she is to her work. 
    
    I'd recommend her any day and would love to work with her again!`,
    avatar:
      'https://res.cloudinary.com/djvbl7f0n/image/upload/v1709063521/ryan_gjavez.jpg',

    link: 'https://www.linkedin.com/in/mu-nil/',
    where: 'NexCloud',
  },
  {
    name: 'Shrishti Raj',
    info: 'Associate Consultant | Developer',
    location: 'Uttar Pradesh, Noida, India',
    content: `It was great working with Saemsol on a good Project. We worked closely together for around 1 year. She has great technical and problem solving skills, where she was always helping to other team member , if someone stuck in something. I found her to be hard-working, bright, and a skilled communicator. 

    I’ve watched her constantly look for new challenges to tackle, whether it’s getting to the root cause of any bugs , writing a code. As we are from different country have different language but never felt communication barrier as she is very in communicating with us in English.
    
    She has been a great resource for our team . She has done a great job on the projects. She met deadlines and helped build long-term relationships with clients. Her work is always on top, and she always welcomes feedback and improvements
    
    Saemsol would be an asset to any team.`,
    avatar:
      'https://res.cloudinary.com/djvbl7f0n/image/upload/v1709063527/shrishti_rjivox.jpg',
    link: 'https://www.linkedin.com/in/shrishti-raj-53586698/',
    where: 'NexCloud',
  },
];
