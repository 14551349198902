import { createGlobalStyle } from 'styled-components';

import { noScrollbar } from './utils';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color:#292b2c;
    
  }

  :root {
    //colors
    /* --w3m-z-index: 10000 !important; */
  }

  html {
    width: 100%;
    scroll-behavior: smooth;
    /* min-height: -webkit-fill-available; */
    /* overflow: hidden; */
    /* font-size: 16px; */
    /* color: var(--white); */
    /* background-color: var(--primary-900); */
  }

  /* body {
    ${noScrollbar()}
    width: 100%;
    max-width: 100vw;
    min-height: -webkit-fill-available;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 100ms;
  } */

  body {
    ${noScrollbar()}
  }


  h1 {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a,
  button {
    cursor: pointer;
  }

  button {
    padding: 0;
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  ul,
  ol {
    padding-left: 0;
    list-style: none;
  }
`;

export default GlobalStyle;
