import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { flexbox } from 'styles/utils';

export default function Navbar() {
  return (
    <Nav>
      <Logo>Selena.dev</Logo>
      <Menu>
        <MenuItem>
          <a href="#index">Index</a>
        </MenuItem>
        <MenuItem>
          <a href="#projects">Projects</a>
        </MenuItem>
        <MenuItem>
          <a href="#testimonial">Testimonial</a>
        </MenuItem>
      </Menu>
      <Link className="resume" to="/resume" target="_blank">
        📄 Resume
      </Link>
    </Nav>
  );
}

const Nav = styled.nav`
  ${flexbox('space-between', 'center')}
  width: 100%;
  padding: 25px 40px;
  position: fixed;
  top: 0;
  z-index: 99999;
  backdrop-filter: blur(10px);

  .resume {
    font-weight: 500;
  }
`;

const Logo = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const Menu = styled.ul`
  ${flexbox()}
  gap: 30px;

  @media (max-width: 500px) {
    display: none;
  }
`;

const MenuItem = styled.li``;
