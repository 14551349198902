import { Route, Routes, Navigate } from 'react-router-dom';
import Home from 'pages/Home/Home';
import Resume from 'pages/Resume/Resume';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
