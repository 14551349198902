import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import WorkExperienceBox from './WorkExperienceBox';
import { works } from './resumeContents';
import { opensources } from './opensourceContents';
import OpensourceBox from './OpensourceBox';

export default function Resume() {
  const [width, setWidth] = useState<number>(0);
  const progressRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback((): void => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop === 0) {
      // 스크롤바가 가장 위에있을때는 0으로 처리
      setWidth(0);
      return;
    }

    const windowHeight: number = scrollHeight - clientHeight;
    // 스크롤바 크기 = (내용 전체의 높이) - (스크롤바를 제외한 클라이언트 높이)

    const currentPercent: number = scrollTop / windowHeight;
    // 스크롤바 크기 기준으로 scrollTop이 내려온만큼에 따라 계산 (계산시 소수점 둘째자리까지 반환)

    setWidth(currentPercent * 100);
    // 소수점 둘째자리 까지이므로, 100을 곱하여 정수로 만들어줍니다.
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <ResumePage>
      <Helmet>
        <title>Resume ⎮ Selena Yoo</title>
      </Helmet>
      <ScrollProgress ref={progressRef}>
        <div style={{ width: width + '%' }}></div>
      </ScrollProgress>
      <Section>
        <h1>
          Hello
          <br />I am Selena Yoo.
        </h1>
        <p>
          I am a 3-year experienced front-end developer, Selena. I have a strong
          passion for both development and UI/UX design. I firmly believe that a
          smooth and intuitive user interface plays a crucial role in leaving a
          positive impression on the product. <br />
          That's why I'm highly dedicated to this field. <br />
          For me, web development is all about creating something new, and I'm
          deeply passionate about it. I am always excited about the opportunity
          to share and collaborate on these experiences. It would be a pleasure
          to work together!
        </p>
        <Title>Work Experience</Title>
        {works?.map((work) => {
          return <WorkExperienceBox work={work} />;
        })}

        <Title>Open Source</Title>
        {opensources?.map((os) => {
          return <OpensourceBox os={os} />;
        })}
        {/* 
        <Title>Skills</Title> */}
        <Title>Contact</Title>
        <Contact>
          <li>
            <a
              href="mailto:yoosaemsol@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Email
            </a>
          </li>
          <li>
            <a
              href="https://github.com/yoosaemsol"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/saemsol-yoo/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </Contact>
      </Section>
    </ResumePage>
  );
}

const Contact = styled.ul`
  padding-bottom: 50px;

  li {
    line-height: 1.5;
    font-size: 25px;
    padding-left: 10px;
    position: relative;
    font-weight: 500;

    &::before {
      position: absolute;
      top: 1.1rem;
      left: 0px;
      width: 4px;
      height: 4px;
      background-color: black;
      border-radius: 50%;
      content: '';
    }

    a {
      &::after {
        margin-left: 0.15em;
        font-weight: 500;
        content: '↗️';
      }
    }
  }

  @media (max-width: 688px) {
    li {
      font-size: 15px;

      &::before {
        top: 0.6rem;
      }
    }
  }
`;

const ResumePage = styled.div`
  width: 100%;
  height: 200vh;
  padding-top: 30px;

  h1::selection,
  h3::selection,
  p::selection {
    color: white;
    background-color: black;
  }
`;

const ScrollProgress = styled.div`
  width: 100%;
  height: 20px;
  background-color: inherit;
  backdrop-filter: blur(10px);

  position: fixed;
  /* 가장 상단에 고정 배치 */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  & > div {
    height: 100%;
    background-color: #000000;
  }
`;

const Section = styled.section`
  width: 688px;
  height: 100%;
  margin: 0 auto;
  padding-top: 100px;
  h1 {
    font-size: 5rem;
    margin-bottom: 50px;
  }

  p {
    line-height: 1.5;
    font-size: 30px;
    white-space: pre-line;
  }

  @media (max-width: 688px) {
    width: 100%;
    padding: 0 1.5rem;
    padding-top: 60px;

    h1 {
      font-size: 3rem;
      margin-bottom: 2.5rem;
    }

    p {
      font-size: 20px;
    }
  }
`;

const Title = styled.h3`
  font-size: 2.4rem;
  margin-top: 8rem;
  margin-bottom: 2rem;

  &::after {
    content: ';';
    opacity: 0.25;
    margin-left: 0.1em;
  }

  @media (max-width: 688px) {
    font-size: 1.8rem;
    margin-top: 5rem;
  }
`;
