import React, { useState } from 'react';
import styled from 'styled-components';
import TypeIt from 'typeit-react';
import { flexbox } from 'styles/utils';

export default function IndexSection() {
  const [active, setActive] = useState<null | number>(null);

  const handleDetail = (id: number) => {
    setActive((prev: any) => {
      if (prev === id) {
        return null;
      }

      return id;
    });
  };

  return (
    <Wrapper id="index">
      <LinkSection>
        <div>
          <p>yoosaemsol@gmail.com</p>
          <p>Toronto, ON</p>
        </div>
        <ul>
          <li>
            <a
              href="https://github.com/yoosaemsol"
              target="_blank"
              rel="noreferrer"
            >
              Github →
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/saemsol-yoo/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn →
            </a>
          </li>
        </ul>
      </LinkSection>
      <Center>
        <TypeSection>
          <h2>Hello</h2>
          <span>
            I'm {` `}
            <TypeIt
              className="type"
              element={'strong'}
              getBeforeInit={(instance) => {
                instance
                  .type('Developer')
                  .move(-9)
                  .type('Energetic ') //Energetic |Developer
                  .move(null, { to: 'END' }) //Energetic Developer|
                  .type(' ✨') //Energetic Developer ✨|
                  .pause(1000)
                  .delete() //|
                  .type('Front-end Engineer') //Front-end Engineer|
                  .pause(1000)
                  .move(-9) //Front-end| Engineer
                  .delete(9) //| Engineer
                  .type('Sometimes Back-end') //Sometimes Back-end| Engineer
                  .pause(1000)
                  .move(null, { to: 'END' }) //Sometimes Back-end Engineer|
                  .delete() //|
                  .type('Javascript Enthusiast 🔥') //Javascript Enthusiast|
                  .pause(1000)
                  .delete()
                  .type('Traveller 🌎') //Traveller|
                  .pause(1000)
                  .delete()
                  .go();

                // Remember to return it!
                return instance;
              }}
              options={{
                loop: true,
                speed: 100,
              }}
            />
            , Selena
          </span>
        </TypeSection>
        <Contents>
          <p>
            Hi, I'm Selena, a frontend developer based in Toronto, Ontario. Yes,
            I love designing and building digital products. Over the past 3+
            years, I've been working in startups and collaborating with talented
            people to create SaaS applications for both business and consumer
            use. I'm a fast learner, always eager to keep learning and to
            continue challenging myself. I enjoy developing expertise in various
            areas throughout my life and career.
          </p>
          <ul>
            <li>
              <span onClick={() => handleDetail(0)}>Languages</span>
              {active === 0 && (
                <MoreInfo>
                  <p>Korean</p>
                  <p>English</p>
                </MoreInfo>
              )}
            </li>
            <li>
              <span onClick={() => handleDetail(1)}>Skills</span>
              {active === 1 && (
                <MoreInfo>
                  <p>HTML5, CSS3, JavaScript</p>
                  <p>ES6+, TypeScript</p>
                  <p>React, Redux</p>
                  <p>PostCSS, StyledComponent</p>
                  <p>Express.js</p>
                  <p>Figma</p>
                </MoreInfo>
              )}
            </li>
            <li>
              <span onClick={() => handleDetail(2)}>Work experience</span>
              {active === 2 && (
                <MoreInfo>
                  <p>Frontend Developer @ NexCloud / 2021-2023</p>
                  <p>Frontend Developer @ Claion / 2023</p>
                </MoreInfo>
              )}
            </li>
            <li>
              <span onClick={() => handleDetail(3)}>Etc</span>
              {active === 3 && (
                <MoreInfo>
                  <p>
                    Staff at KubeCon + CloudNativeCon North America / Detroit,
                    U.S. / 2022
                  </p>
                </MoreInfo>
              )}
            </li>
          </ul>
        </Contents>
      </Center>
      <Orbit>
        <div className="track"></div>
        <div className="ball"></div>
      </Orbit>
    </Wrapper>
  );
}

const Orbit = styled.div`
  z-index: -999;
  position: absolute;
  bottom: -150px;
  left: -100px;
  width: 800px;
  height: 800px;
  animation: rotate 8s linear infinite;

  .track {
    width: 800px;
    height: 800px;
    border: 1px solid hsla(0, 0%, 50%, 0.227);
    border-radius: 50%;
  }

  .ball {
    width: 20px;
    height: 20px;
    background-color: #98b7db;
    position: absolute;
    left: 400px;
    top: -10px;
    border-radius: 50%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg); /* 0도 회전 시작 */
    }
    100% {
      transform: rotate(360deg); /* 360도 회전 종료 */
    }
  }

  @media (max-width: 500px) {
    width: 300px;
    height: 300px;
    top: 500px;

    .track {
      width: 300px;
      height: 300px;
    }

    .ball {
      width: 16px;
      height: 16px;
      left: 150px;
      top: -8px;
    }
  }
`;

const Wrapper = styled.section`
  ${flexbox('center', 'flex-start')}
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  padding: 0 40px;
  padding-top: 80px;
  position: relative;

  @media (max-width: 500px) {
    min-height: 180vh;
    height: fit-content;
  }

  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% + 1.3px);
    height: 60px;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    background-color: #f5f4f4;
    mask: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none" > <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" ></path> </svg>');
    -webkit-mask: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none" > <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" ></path> </svg>');
    z-index: -999;
  }
`;

const LinkSection = styled.div`
  position: absolute;
  top: 100px;
  left: 40px;
  font-size: 20px;
  z-index: 100;

  div {
    margin-bottom: 20px;
    line-height: 1.5;
  }

  ul {
    line-height: 1.5;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

const Center = styled.div`
  max-width: 40%;

  @media (max-width: 1200px) {
    max-width: 80%;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const TypeSection = styled.div`
  margin-top: 150px;

  h2,
  span {
    font-size: 50px;
    font-weight: 600;
    text-decoration: none;
  }

  span.type {
    color: #98b7db;
  }

  height: 200px;

  @media (max-width: 1200px) {
    margin-top: 240px;
    height: 220px;
  }

  @media (max-width: 500px) {
    h2,
    span {
      font-size: 30px;
    }

    margin-top: 0px;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    transform: translateY(-30px);
  }
`;

const Contents = styled.div`
  font-size: 20px;
  line-height: 1.5;

  p {
    margin-bottom: 20px;
  }

  span {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    min-height: 80vh;
    height: fit-content;
    font-size: 16px;
    padding-bottom: 40px;
  }
`;

const MoreInfo = styled.div`
  padding-bottom: 30px;

  p {
    margin: 0px;
  }

  @media (max-width: 500px) {
    padding-bottom: 10px;
  }
`;
